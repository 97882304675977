import { Injectable, inject } from '@angular/core';
import { UserApiService } from '@api-new/userservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadInitialPlatformData } from '@shared/store/common.actions';
import {
  createPortfolioFolder,
  createPortfolioFolderSuccess,
  getPortfolioFolders,
  getPortfolioFoldersFailure,
  getPortfolioFoldersSuccess,
} from '@shared/store/portfolio-folders/portfolio-folders.actions';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class PortfolioFoldersEffects {
  private readonly userApiService = inject(UserApiService);
  private readonly actions$ = inject(Actions);

  handleLoadInitialPlatformData = createEffect(() => this.actions$.pipe(ofType(loadInitialPlatformData), map(getPortfolioFolders)));

  getPortfolioFolders = createEffect(() =>
    this.actions$.pipe(
      ofType(getPortfolioFolders),
      switchMap(() => this.userApiService.HTTP_CP_ListPortfolioFolders()),
      map(({ portfolioFolders }) => getPortfolioFoldersSuccess({ portfolioFolders })),
      catchError((error) => of(getPortfolioFoldersFailure({ error }))),
    ),
  );

  createPortfolioFolder = createEffect(() =>
    this.actions$.pipe(
      ofType(createPortfolioFolder),
      switchMap((action) => this.userApiService.HTTP_CP_CreatePortfolioFolder({ name: action.name })),
      map((portfolioFolder) => createPortfolioFolderSuccess({ portfolioFolder })),
      catchError((error) => of(getPortfolioFoldersFailure({ error }))),
    ),
  );
}
