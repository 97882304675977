import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageSyncService {
  saveToStorage<T extends object & { type?: string }>(feature: string, data: T): void {
    localStorage.setItem(feature, JSON.stringify(data));
  }

  loadFromStorage<T extends object>(feature: string): T | null {
    const storageItem = localStorage.getItem(feature);
    if (storageItem != null) {
      return JSON.parse(storageItem);
    } else {
      return null;
    }
  }

  deleteFromStorage(feature: string): void {
    localStorage.removeItem(feature);
  }
}
