import { HTTP_X_CompareMortgageProducts_Response_Product } from '@api-new/mortgageservice';
import { SmartSearchFormValues } from '@app/sign-up/utils/smart-search-form';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/app-state.model';
import { SignUpSmartSearchState } from '@shared/store/sign-up/smart-search/smart-search.state';

export const loadSignUpSmartSearchFromStorage = createAction('[SIGN UP] Load Smart Search From Storage');
export const loadSignUpSmartSearchFromStorageSuccess = createAction(
  '[SIGN UP]  Load Smart Search From Storage Success',
  props<{ state: SignUpSmartSearchState | null }>(),
);

export const setSignUpSmartSearchForm = createAction('[SIGN UP]  Set Smart Search Form', props<SmartSearchFormValues>());

export const clearSignUpSmartSearchFromStorage = createAction('[SIGN UP] Clear Smart Search Form');

export const getSignUpSmartSearchMortgageProducts = createAction(
  '[SIGN UP]  Get Smart Search Mortgage Products',
  props<SmartSearchFormValues>(),
);
export const getSignUpSmartSearchMortgageProductsSuccess = createAction(
  '[SIGN UP]  Get Smart Search Mortgage Products Success',
  props<{ products: HTTP_X_CompareMortgageProducts_Response_Product[]; numberOfProducts: number }>(),
);
export const getSignUpSmartSearchMortgageProductsFailure = createAction(
  '[SIGN UP]  Get Smart Search Mortgage Products Failure',
  props<{ error: ErrorModel }>(),
);

export const skipSignUpSmartSearchCreation = createAction('[SIGN UP] Skip Smart Search Creation');
