import { HTTP_CP_PortfolioFolder } from '@api-new/userservice';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  createPortfolioFolderSuccess,
  getPortfolioFolders,
  getPortfolioFoldersFailure,
  getPortfolioFoldersSuccess,
  updatePortfolioFolderSuccess,
} from '@shared/store/portfolio-folders/portfolio-folders.actions';
import { PortfolioFoldersState } from '@shared/store/portfolio-folders/portfolio-folders.state';

export const getPortfolioFolderById = (portfolioFolder: Pick<HTTP_CP_PortfolioFolder, 'id'>): string => portfolioFolder.id;

export const portfolioFoldersAdapter: EntityAdapter<HTTP_CP_PortfolioFolder> = createEntityAdapter<HTTP_CP_PortfolioFolder>({
  selectId: getPortfolioFolderById,
});

export const portfolioFoldersInitialState: PortfolioFoldersState = portfolioFoldersAdapter.getInitialState({
  loading: true,
  errors: null,
});

const reducer = createReducer(
  portfolioFoldersInitialState,

  on(getPortfolioFolders, (state) => ({
    ...state,
    loading: true,
  })),

  on(getPortfolioFoldersSuccess, (state, { portfolioFolders }) =>
    portfolioFoldersAdapter.setAll(portfolioFolders ? portfolioFolders : [], {
      ...state,
      loading: false,
      errors: null,
    }),
  ),

  on(getPortfolioFoldersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),

  on(createPortfolioFolderSuccess, (state, { portfolioFolder }) => portfolioFoldersAdapter.addOne(portfolioFolder, state)),

  on(updatePortfolioFolderSuccess, (state, { portfolioFolder }) =>
    portfolioFoldersAdapter.updateOne(
      {
        id: portfolioFolder.id,
        changes: portfolioFolder,
      },
      state,
    ),
  ),
);

export function portfolioFoldersReducer(
  state: PortfolioFoldersState = portfolioFoldersInitialState,
  action: Action,
): PortfolioFoldersState {
  return reducer(state, action);
}
