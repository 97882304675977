import { ENUM_ClientUserViewType } from '@api-new/common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClientUserState } from '@shared/store/client-user/client-user.state';
import { selectPropertiesNumber } from '@shared/store/properties/properties.selectors';

export const selectClientUserState = createFeatureSelector<ClientUserState>('clientUser');

export const selectClientUser = createSelector(selectClientUserState, (state: ClientUserState) => state.clientUser);

export const selectClientUserId = createSelector(selectClientUser, (user): string | null => (user != null && user.id) || null);

export const selectClientUserHasUserAffiliate = createSelector(selectClientUser, (user): boolean => user?.affiliateId != null);

export const selectClientUserEmail = createSelector(selectClientUser, (user): string => user?.email || 'unknown e-mail');

export const selectClientUserFullName = createSelector(selectClientUser, (user): string => {
  if (user == null || (user.firstName == null && user.lastName == null)) {
    return ` `;
  }
  return user.fullName;
});

export const selectClientUserIsLoading = createSelector(selectClientUserState, (state): boolean => (state && state.loading) || false);

export const selectClientUserIsLoaded = createSelector(
  selectClientUserState,
  (state): boolean => !state.loading && state.clientUser != null,
);

export const selectClientUserDeclaredNumberOfProperties = createSelector(
  selectClientUserState,
  (state): number => state.clientUser?.declaredNumberOfProperties ?? 0,
);

export const selectClientUserRemainingPropertiesNumber = createSelector(
  selectClientUserDeclaredNumberOfProperties,
  selectPropertiesNumber,
  (declaredProperties, actualProperties): number => declaredProperties - actualProperties,
);

export const selectIsClientUserViewTypeLandlord = createSelector(
  selectClientUser,
  (user): boolean => user.clientUserViewType === ENUM_ClientUserViewType.CLIENT_USER_VIEW_TYPE_LANDLORD,
);
