import { HTTP_CP_PortfolioFolder } from '@api-new/userservice';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/app-state.model';

export const getPortfolioFolders = createAction('[PORTFOLIO-FOLDERS] Get Portfolio Folders');
export const getPortfolioFoldersSuccess = createAction(
  '[PORTFOLIO-FOLDERS] Get Portfolio Folders Success',
  props<{ portfolioFolders: HTTP_CP_PortfolioFolder[] }>(),
);
export const getPortfolioFoldersFailure = createAction('[PORTFOLIO-FOLDERS] Get Portfolio Folders', props<{ error: ErrorModel }>());

export const createPortfolioFolder = createAction('[PORTFOLIO-FOLDERS] Create Portfolio Folder', props<{ name: string }>());
export const createPortfolioFolderSuccess = createAction(
  '[PORTFOLIO-FOLDERS] Create Portfolio Folder Success',
  props<{ portfolioFolder: HTTP_CP_PortfolioFolder }>(),
);
export const createPortfolioFolderFailure = createAction(
  '[PORTFOLIO-FOLDERS] Create Portfolio Folder Failure',
  props<{
    error: ErrorModel;
  }>(),
);

export const updatePortfolioFolderSuccess = createAction(
  '[PORTFOLIO-FOLDERS] Update Portfolio Folder Success',
  props<{ portfolioFolder: HTTP_CP_PortfolioFolder }>(),
);

export const deletePortfolioFolder = createAction('[PORTFOLIO-FOLDERS] Delete Portfolio Folder', props<{ portfolioFolderId: string }>());
export const deletePortfolioFolderSuccess = createAction(
  '[PORTFOLIO-FOLDERS] Delete Portfolio Folder Success',
  props<{ portfolioFolderId: string }>(),
);
export const deletePortfolioFolderFailure = createAction(
  '[PORTFOLIO-FOLDERS] Delete Portfolio Folder Failure',
  props<{
    error: ErrorModel;
  }>(),
);

export const addPortfolioItemToFolder = createAction(
  '[PORTFOLIO-FOLDERS] Add Portfolio Item To Folder',
  props<{ portfolioFolderId: string; propertyId: string; smartSearchId: string }>(),
);
export const addPortfolioItemToFolderSuccess = createAction('[PORTFOLIO-FOLDERS] Add Portfolio Item To Folder Success');
export const addPortfolioItemToFolderFailure = createAction('[PORTFOLIO-FOLDERS] Add Portfolio Item To Folder Failure');

export const removePortfolioItemFromFolder = createAction(
  '[PORTFOLIO-FOLDERS] Remove Portfolio Item From Folder',
  props<{ portfolioFolderId: string; propertyId: string; smartSearchId: string }>(),
);
export const removePortfolioItemFromFolderSuccess = createAction('[PORTFOLIO-FOLDERS] Remove Portfolio Item From Folder Success');
export const removePortfolioItemFromFolderFailure = createAction('[PORTFOLIO-FOLDERS] Remove Portfolio Item From Folder Failure');
