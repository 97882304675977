// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/authservice/HTTP_CP_SignUp_Request.proto
import { DATA_Address } from '../common/DATA_Address';
import { ENUM_ManualPropertyValueType } from '../common/ENUM_ManualPropertyValueType';
import { ENUM_ManualPropertyValueTypeInspectionSurveyor } from '../common/ENUM_ManualPropertyValueTypeInspectionSurveyor';
import { ENUM_MortgagePartStepType } from '../common/ENUM_MortgagePartStepType';
import { ENUM_MortgageProductPurpose } from '../common/ENUM_MortgageProductPurpose';
import { ENUM_MortgageRepaymentType } from '../common/ENUM_MortgageRepaymentType';
import { ENUM_MortgageType } from '../common/ENUM_MortgageType';
import { ENUM_PropertyInvestmentType } from '../common/ENUM_PropertyInvestmentType';
import { ENUM_PropertyTenure } from '../common/ENUM_PropertyTenure';
import { ENUM_PropertyType } from '../common/ENUM_PropertyType';

export interface HTTP_CP_SignUp_Request {
  clientUser: HTTP_CP_SignUp_Request_ClientUser | undefined;
  mortgageMonitor?: HTTP_CP_SignUp_Request_MortgageMonitor | undefined;
  remortgageNow?: HTTP_CP_SignUp_Request_RemortgageNow | undefined;
  firstTimeBuyer?: HTTP_CP_SignUp_Request_FirstTimeBuyer | undefined;
  movingHome?: HTTP_CP_SignUp_Request_MovingHome | undefined;
  buyToLet?: HTTP_CP_SignUp_Request_BuyToLet | undefined;
  landlord?: HTTP_CP_SignUp_Request_Landlord | undefined;
}

export enum HTTP_CP_SignUp_Request_ENUM_SignUpCreationMethod {
  SIGN_UP_CREATION_METHOD_UNSPECIFIED = 'SIGN_UP_CREATION_METHOD_UNSPECIFIED',
  SIGN_UP_CREATION_METHOD_MORTGAGE_MONITOR = 'SIGN_UP_CREATION_METHOD_MORTGAGE_MONITOR',
  SIGN_UP_CREATION_METHOD_REMORTGAGE_NOW = 'SIGN_UP_CREATION_METHOD_REMORTGAGE_NOW',
  SIGN_UP_CREATION_METHOD_FIRST_TIME_BUYER = 'SIGN_UP_CREATION_METHOD_FIRST_TIME_BUYER',
  SIGN_UP_CREATION_METHOD_MOVING_HOME = 'SIGN_UP_CREATION_METHOD_MOVING_HOME',
  SIGN_UP_CREATION_METHOD_BUY_TO_LET = 'SIGN_UP_CREATION_METHOD_BUY_TO_LET',
  SIGN_UP_CREATION_METHOD_LANDLORD = 'SIGN_UP_CREATION_METHOD_LANDLORD',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export interface HTTP_CP_SignUp_Request_MortgageMonitor {
  property: HTTP_CP_SignUp_Request_Property | undefined;
  mortgage?: HTTP_CP_SignUp_Request_Mortgage | undefined;
}

export interface HTTP_CP_SignUp_Request_RemortgageNow {
  property: HTTP_CP_SignUp_Request_Property | undefined;
  mortgage?: HTTP_CP_SignUp_Request_Mortgage | undefined;
  smartSearchAndSmartSearchCaseAndMortgage?: HTTP_CP_SignUp_Request_RemortgageNow_SmartSearchAndSmartSearchCaseAndMortgage | undefined;
}

export interface HTTP_CP_SignUp_Request_RemortgageNow_SmartSearchAndSmartSearchCaseAndMortgage {
  smartSearch: HTTP_CP_SignUp_Request_SmartSearch | undefined;
  smartSearchCase?: HTTP_CP_SignUp_Request_SmartSearchCase | undefined;
  mortgage?: HTTP_CP_SignUp_Request_Mortgage | undefined;
}

export interface HTTP_CP_SignUp_Request_FirstTimeBuyer {
  smartSearch: HTTP_CP_SignUp_Request_SmartSearch | undefined;
  smartSearchCase?: HTTP_CP_SignUp_Request_SmartSearchCase | undefined;
}

export interface HTTP_CP_SignUp_Request_MovingHome {
  movingHomeWithProperty?: HTTP_CP_SignUp_Request_MovingHome_MovingHomeWithProperty | undefined;
  movingHomeWithSmartSearch?: HTTP_CP_SignUp_Request_MovingHome_MovingHomeWithSmartSearch | undefined;
}

export interface HTTP_CP_SignUp_Request_MovingHome_MovingHomeWithProperty {
  property: HTTP_CP_SignUp_Request_Property | undefined;
  mortgage: HTTP_CP_SignUp_Request_Mortgage | undefined;
}

export interface HTTP_CP_SignUp_Request_MovingHome_MovingHomeWithSmartSearch {
  smartSearch: HTTP_CP_SignUp_Request_SmartSearch | undefined;
  smartSearchCase: HTTP_CP_SignUp_Request_SmartSearchCase | undefined;
}

export interface HTTP_CP_SignUp_Request_BuyToLet {
  smartSearch: HTTP_CP_SignUp_Request_SmartSearch | undefined;
  smartSearchCase?: HTTP_CP_SignUp_Request_SmartSearchCase | undefined;
}

export interface HTTP_CP_SignUp_Request_Landlord {
  declaredNumberOfProperties: number;
}

export interface HTTP_CP_SignUp_Request_ClientUser {
  email: string;
  phoneNumber: string;
  firstName: string;
  middleName?: string | undefined;
  lastName: string;
  dateOfBirth: string | undefined;
  referralCode?: string | undefined;
  residentialAddressId?: string | undefined;
  residentialAddress?: DATA_Address | undefined;
}

export interface HTTP_CP_SignUp_Request_Property {
  addressId?: string | undefined;
  address?: DATA_Address | undefined;
  investmentType: ENUM_PropertyInvestmentType;
  propertyValue: HTTP_CP_SignUp_Request_Property_PropertyValue | undefined;
  additionalPropertyData?: HTTP_CP_SignUp_Request_Property_AdditionalPropertyData | undefined;
}

export interface HTTP_CP_SignUp_Request_Property_PropertyValue {
  year: number;
  month: number;
  manualValue: number;
  manualPropertyValueType: ENUM_ManualPropertyValueType;
  manualPropertyValueTypeInspectionSurveyor?: ENUM_ManualPropertyValueTypeInspectionSurveyor | undefined;
  manualPropertyValueTypeInspectionSurveyorOther?: string | undefined;
}

export interface HTTP_CP_SignUp_Request_Property_AdditionalPropertyData {
  propertyType?: ENUM_PropertyType | undefined;
  constructionYear?: number | undefined;
  numberOfBedrooms?: number | undefined;
  ownershipShare?: number | undefined;
  tenure?: ENUM_PropertyTenure | undefined;
  isCompanyOwned: boolean;
  companyOwnedCompanyRegistrationNumber?: string | undefined;
  companyOwnedCompanyRegisteredName?: string | undefined;
}

export interface HTTP_CP_SignUp_Request_Mortgage {
  writtenByCompanyId?: string | undefined;
  lenderId: string;
  mortgageParts: HTTP_CP_SignUp_Request_Mortgage_MortgagePart[];
}

export interface HTTP_CP_SignUp_Request_Mortgage_MortgagePart {
  initialAmount: number;
  repaymentType: ENUM_MortgageRepaymentType;
  mortgagePartFromMortgageProduct?: HTTP_CP_SignUp_Request_Mortgage_MortgagePart_MortgagePartFromMortgageProduct | undefined;
  mortgagePartFromData?: HTTP_CP_SignUp_Request_Mortgage_MortgagePart_MortgagePartFromData | undefined;
}

export interface HTTP_CP_SignUp_Request_Mortgage_MortgagePart_MortgagePartFromMortgageProduct {
  mortgageProductId: string;
  startDate: string | undefined;
  endDate: string | undefined;
}

export interface HTTP_CP_SignUp_Request_Mortgage_MortgagePart_MortgagePartFromData {
  initialMortgagePartStepType: ENUM_MortgagePartStepType;
  startDate: string | undefined;
  endDate: string | undefined;
  initialPeriodEndDate: string | undefined;
  initialInterestRate: number;
}

export interface HTTP_CP_SignUp_Request_SmartSearch {
  minimumInitialRatePeriodInMonths: number;
  maximumInitialRatePeriodInMonths: number;
  includeInitialRateTypeFixed: boolean;
  includeInitialRateTypeVariable: boolean;
  propertyValue?: number | undefined;
  preferredComparisonPeriodInMonths: number;
  lenderId?: string | undefined;
  mortgageType: ENUM_MortgageType;
  mortgageProductPurpose?: ENUM_MortgageProductPurpose | undefined;
  smartSearchParts: HTTP_CP_SignUp_Request_SmartSearch_SmartSearchPart[];
}

export interface HTTP_CP_SignUp_Request_SmartSearch_SmartSearchPart {
  loanAmount?: number | undefined;
  deposit?: number | undefined;
  termInMonths: number;
  repaymentType: ENUM_MortgageRepaymentType;
}

export interface HTTP_CP_SignUp_Request_SmartSearchCase {
  mortgageProductId: string;
}
